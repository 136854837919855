import React from "react";
import { Alert, Card, Col, Form, Row, Table } from "react-bootstrap";

import AdverseEffects from "../api/AdverseEffects";
import { isDataEmpty } from "../utils";
import LoaderButton from "./LoaderButton";

class GetAdverseEffects extends React.Component {
  state = {
    centerId: "",
    isLoading: false,
    effects: [],
    error: null,
    showAlert: false,
  };

  _setCenterId(centerId) {
    this.setState({ centerId });
  }

  _setIsLoading(isLoading) {
    this.setState({ isLoading });
  }

  _canonicalizeData(data) {
    if (isDataEmpty(data) || isDataEmpty(data.data)) {
      return [];
    }

    return data.data.map((datum) => ({
      id: datum.id,
      effect: datum.effect,
      aadhar: datum.aadhar,
      timestamp: datum.timestamp,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    this._setIsLoading(true);
    try {
      const data = await AdverseEffects(this.state.centerId, this.state.aadhar);
      this.setState({
        showAlert: false,
        isLoading: false,
        effects: this._canonicalizeData(data),
        error: null,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        showAlert: true,
        isLoading: false,
        effects: [],
        error: error.message,
      });
    }
  }

  FormIsInvalid() {
    return isDataEmpty(this.state.centerId);
  }

  _renderAdverseEffect(item, index) {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{item.aadhar}</td>
        <td>{item.effect}</td>
        <td>
          {new Date(item.timestamp).toLocaleString("en-GB", {
            timeZone: "Asia/Kolkata",
            month: "short",
            day: "numeric",
            year: "numeric",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      </tr>
    );
  }

  _renderEffects() {
    if (this.state.effects.length === 0) {
      return null;
    }

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Aadhar</th>
            <th>Adverse effect</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {this.state.effects.map((item, index) =>
            this._renderAdverseEffect(item, index)
          )}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <Card className="AdverseEffects my-2">
        <Card.Header>
          <h2>Get adverse effects</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group as={Row} controlId="centerId">
              <Form.Label column sm={4}>
                Center Id
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.centerId}
                  type={"text"}
                  placeholder={"Enter Center Id"}
                  onChange={(e) => this._setCenterId(e.target.value)}
                />
              </Col>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              isLoading={this.state.isLoading}
              isDisabled={this.FormIsInvalid.bind(this)}
            >
              Fetch
            </LoaderButton>
          </Form>
          {this.state.showAlert ? (
            <Alert
              variant={isDataEmpty(this.state.error) ? "success" : "danger"}
              dismissible
              onClose={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              {isDataEmpty(this.state.error) ? "Submitted" : this.state.error}
            </Alert>
          ) : null}
          <p />
          {this._renderEffects()}
        </Card.Body>
      </Card>
    );
  }
}

export default GetAdverseEffects;
