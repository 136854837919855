import { API } from "@aws-amplify/api";
import { API_NAME } from "../constants";

function ReportAdverseEffects(aadhar, effect) {
  return API.post(API_NAME, "/reportadverseeffect", {
    body: {
      aadhar,
      effect,
    },
  });
}

export default ReportAdverseEffects;
