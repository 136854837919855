import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import ValidToken from "../api/ValidToken";
import { isDataEmpty } from "../utils";
import LoaderButton from "./LoaderButton";

class GetValidToken extends React.Component {
  state = {
    aadhar: "",
    centerId: "",
    tokenNumber: "",
    isLoading: false,
    error: null,
    showAlert: false,
  };

  _showAlert() {
    return this.state.showAlert;
  }

  _setAadhar(aadhar) {
    this.setState({ aadhar });
  }

  _setCenterId(centerId) {
    this.setState({ centerId });
  }

  _setIsLoading(isLoading) {
    this.setState({ isLoading });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this._setIsLoading(true);
    try {
      const data = await ValidToken(this.state.centerId, this.state.aadhar);
      this.setState({
        showAlert: false,
        isLoading: false,
        tokenNumber:
          isDataEmpty(data) || isDataEmpty(data.tokenNumber)
            ? "No token found"
            : data.tokenNumber,
        error: null,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        showAlert: true,
        isLoading: false,
        tokenNumber: "No token found",
        error: error.message,
      });
    }
  }

  FormIsInvalid() {
    return isDataEmpty(this.state.centerId) || isDataEmpty(this.state.aadhar);
  }

  render() {
    return (
      <Card className="ValidToken my-2">
        <Card.Header>
          <h2>Get existing token for a patient</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group as={Row} controlId="centerId">
              <Form.Label column sm={4}>
                Center Id
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.centerId}
                  type={"text"}
                  placeholder={"Enter Center Id"}
                  onChange={(e) => this._setCenterId(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="aadhar">
              <Form.Label column sm={4}>
                Aadhar
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.aadhar}
                  type={"text"}
                  placeholder={"Enter Aadhar"}
                  onChange={(e) => this._setAadhar(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tokenNumber">
              <Form.Label column sm={4}>
                Token Number
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  disabled
                  value={this.state.tokenNumber}
                  type={"text"}
                />
              </Col>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              isLoading={this.state.isLoading}
              isDisabled={this.FormIsInvalid.bind(this)}
            >
              Fetch
            </LoaderButton>
          </Form>
          {this._showAlert() ? (
            <Alert
              variant={isDataEmpty(this.state.error) ? "success" : "danger"}
              dismissible
              onClose={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              {isDataEmpty(this.state.error) ? "Submitted" : this.state.error}
            </Alert>
          ) : null}
        </Card.Body>
      </Card>
    );
  }
}

export default GetValidToken;
