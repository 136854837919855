import { Col, Container, Row } from "react-bootstrap";

import "./assets/css/App.css";
import ActiveTokenIncrementer from "./components/ActiveTokenIncrementer";
import AdverseEffectReporter from "./components/AdverseEffectReporter";
import GenerateNewToken from "./components/GenerateNewToken";
import GetActiveToken from "./components/GetActiveToken.js";
import GetAdverseEffects from "./components/GetAdverseEffects";
import GetValidToken from "./components/GetValidToken";

function App() {
  return (
    <div className="App">
      <Container className="m-2">
        <Row>
          <Col sm={4}>
            <GenerateNewToken />
            <GetValidToken />
          </Col>
          <Col sm={4}>
            <ActiveTokenIncrementer />
            <GetActiveToken />
          </Col>
          <Col sm={4}>
            <AdverseEffectReporter />
            <GetAdverseEffects />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
