import { API } from "@aws-amplify/api";
import { API_NAME } from "../constants";

function NewToken(centerId, aadhar) {
  return API.post(API_NAME, "/newtoken", {
    body: {
      centerId,
      aadhar,
    },
  });
}

export default NewToken;
