import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import ActiveToken from "../api/ActiveToken";
import { isDataEmpty } from "../utils";
import LoaderButton from "./LoaderButton";

class GetActiveToken extends React.Component {
  state = {
    centerId: "",
    tokenNumber: "",
    isLoading: false,
    error: null,
    showAlert: false,
  };

  _setCenterId(centerId) {
    this.setState({ centerId });
  }

  _setIsLoading(isLoading) {
    this.setState({ isLoading });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this._setIsLoading(true);
    try {
      const data = await ActiveToken(this.state.centerId);
      console.log(data);
      this.setState({
        showAlert: false,
        isLoading: false,
        activeTokenNumber:
          isDataEmpty(data) || isDataEmpty(data.activeTokenNumber)
            ? "No active token"
            : data.activeTokenNumber,
        error: null,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        showAlert: true,
        isLoading: false,
        tokenNumber: "No active token",
        error: error.message,
      });
    }
  }

  FormIsInvalid() {
    return isDataEmpty(this.state.centerId);
  }

  render() {
    return (
      <Card className="ActiveToken my-2">
        <Card.Header>
          <h2>Get the current active token</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group as={Row} controlId="centerId">
              <Form.Label column sm={4}>
                Center Id
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.centerId}
                  type={"text"}
                  placeholder={"Enter Center Id"}
                  onChange={(e) => this._setCenterId(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="activeTokenNumber">
              <Form.Label column sm={4}>
                Active Token
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  disabled
                  value={this.state.activeTokenNumber}
                  type={"text"}
                />
              </Col>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              isLoading={this.state.isLoading}
              isDisabled={this.FormIsInvalid.bind(this)}
            >
              Fetch
            </LoaderButton>
          </Form>
          {this.state.showAlert ? (
            <Alert
              variant={isDataEmpty(this.state.error) ? "success" : "danger"}
              dismissible
              onClose={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              {isDataEmpty(this.state.error) ? "Submitted" : this.state.error}
            </Alert>
          ) : null}
        </Card.Body>
      </Card>
    );
  }
}

export default GetActiveToken;
