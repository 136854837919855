/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-south-1",
  API: {
    endpoints: [
      {
        name: "CowinBE",
        endpoint: "https://kd3or14leg.execute-api.ap-south-1.amazonaws.com/dev",
        region: "ap-south-1",
      },
    ],
  },
};

export default awsmobile;
