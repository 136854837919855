import { API } from "@aws-amplify/api";
import { API_NAME } from "../constants";

function IncrementActiveToken(centerId) {
  return API.post(API_NAME, "/incrementactivetoken", {
    body: {
      centerId,
    },
  });
}

export default IncrementActiveToken;
