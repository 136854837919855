import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import ReportAdverseEffect from "../api/ReportAdverseEffect";
import { isDataEmpty } from "../utils";
import LoaderButton from "./LoaderButton";

class AdverseEffectReporter extends React.Component {
  state = {
    aadhar: "",
    effect: "",
    isLoading: false,
    error: null,
    showAlert: false,
  };

  _setAadhar(aadhar) {
    this.setState({ aadhar });
  }

  _setEffect(effect) {
    this.setState({ effect });
  }

  _setIsLoading(isLoading) {
    this.setState({ isLoading });
  }

  _setError(error) {
    this.setState({ error });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this._setIsLoading(true);
    try {
      const data = await ReportAdverseEffect(
        this.state.aadhar,
        this.state.effect
      );
      console.log(data);
      this._setIsLoading(false);
      this._setError(null);
      this.setState({ showAlert: true });
    } catch (error) {
      console.log(error);
      this._setIsLoading(false);
      this._setError(error.message);
      this.setState({ showAlert: true });
    }
  }

  FormIsInvalid() {
    return isDataEmpty(this.state.aadhar) || isDataEmpty(this.state.effect);
  }

  render() {
    return (
      <Card className="ReportAdverseEffect my-2">
        <Card.Header>
          <h2>Report an adverse effect</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group as={Row} controlId="aadhar">
              <Form.Label column sm={4}>
                Aadhar
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.aadhar}
                  type={"text"}
                  placeholder={"Enter Aadhar"}
                  onChange={(e) => this._setAadhar(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="effect">
              <Form.Label column sm={4}>
                Adverse Effect
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.effect}
                  type={"text"}
                  placeholder={"Enter adverse effect"}
                  onChange={(e) => this._setEffect(e.target.value)}
                />
              </Col>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              isLoading={this.state.isLoading}
              isDisabled={this.FormIsInvalid.bind(this)}
            >
              Report
            </LoaderButton>
          </Form>
          {this.state.showAlert ? (
            <Alert
              variant={isDataEmpty(this.state.error) ? "success" : "danger"}
              dismissible
              onClose={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              {isDataEmpty(this.state.error) ? "Reported" : this.state.error}
            </Alert>
          ) : null}
        </Card.Body>
      </Card>
    );
  }
}

export default AdverseEffectReporter;
