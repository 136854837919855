import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import NewToken from "../api/NewToken";
import { isDataEmpty } from "../utils";
import LoaderButton from "./LoaderButton";

class GenerateNewToken extends React.Component {
  state = {
    aadhar: "",
    centerId: "",
    isLoading: false,
    error: null,
    showAlert: false,
  };

  _showAlert() {
    return this.state.showAlert;
  }

  _setAadhar(aadhar) {
    this.setState({ aadhar });
  }

  _setCenterId(centerId) {
    this.setState({ centerId });
  }

  _setIsLoading(isLoading) {
    this.setState({ isLoading });
  }

  _setError(error) {
    this.setState({ error });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this._setIsLoading(true);
    try {
      const data = await NewToken(this.state.centerId, this.state.aadhar);
      console.log(data);
      this._setIsLoading(false);
      this._setError(null);
      this.setState({ showAlert: true });
    } catch (error) {
      console.log(error);
      this._setIsLoading(false);
      this._setError(error.message);
      this.setState({ showAlert: true });
    }
  }

  FormIsInvalid() {
    return isDataEmpty(this.state.centerId) || isDataEmpty(this.state.aadhar);
  }

  render() {
    return (
      <Card className="NewToken my-2">
        <Card.Header>
          <h2>Get new token for a patient</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group as={Row} controlId="centerId">
              <Form.Label column sm={4}>
                Center Id
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.centerId}
                  type={"text"}
                  placeholder={"Enter Center Id"}
                  onChange={(e) => this._setCenterId(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="aadhar">
              <Form.Label column sm={4}>
                Aadhar
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  value={this.state.aadhar}
                  type={"text"}
                  placeholder={"Enter Aadhar"}
                  onChange={(e) => this._setAadhar(e.target.value)}
                />
              </Col>
            </Form.Group>
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              isLoading={this.state.isLoading}
              isDisabled={this.FormIsInvalid.bind(this)}
            >
              Create
            </LoaderButton>
          </Form>
          {this._showAlert() ? (
            <Alert
              variant={isDataEmpty(this.state.error) ? "success" : "danger"}
              dismissible
              onClose={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              {isDataEmpty(this.state.error) ? "Submitted" : this.state.error}
            </Alert>
          ) : null}
        </Card.Body>
      </Card>
    );
  }
}

export default GenerateNewToken;
